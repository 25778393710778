body {
  font: normal normal 18px/1.5 $font-family;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: $text-color-hover;
}

a {
  text-decoration: none;
  border-bottom: 1px solid $text-color;
  color: $text-color;
  transition: color 0.4s;

  &:hover {
    color: $text-color-hover;
  }
}

h1 {
  font-size: 2em;
  font-weight: normal;
  letter-spacing: .1em;
}

p {
  margin-top: 1.1em;
}

pre {
  line-height: .9em;
  background-color: $code-background;
  border: 1px solid $code-border-color;
  padding: 0.8em;
  margin: 0.7em 0;
}

code {
  font-size: 12px;
}
