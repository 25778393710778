.page-content {
  margin: 60px 20px 100px 20px;

  @media (max-width: $screen-s) {
    margin: 20px 10px 80px 10px;
  }
}

.wrapper {
  max-width: 700px;
  width: auto;
  margin: 0 auto;
}

.main {
  margin-bottom: 1.5em;
}

.main-text {
  border-bottom: 1px dashed $code-border-color;
  padding-bottom: 1.5em;
  line-height: 1.6em;
}

.main-language {
  text-align: right;
  font-style: italic;
  font-size: .9rem;
  margin-top: 10px;
}

.blog-title {
  margin-bottom: 0.9em;
}

.post-list {
  list-style: none;

  li {
    margin-bottom: 1.5em;

    sup {
      margin-left: 0;
      transition: margin-left 0.4s;
    }

    &:hover sup {
      margin-left: 5px;
      transition: margin-left 0.4s;
    }
  }
}

.post-icon {
  color: $text-color;
  padding-right: .3em;
  font-size: .6em;
  vertical-align: middle;
}

.post-date {
  font-size: x-small;
  background-color: $green-color;
  color: white;
  padding: 0 0.5em;
  border-radius: 1em;
}
