.post {
  margin-bottom: 3em;
}

.post-header {
  h2 {
    line-height: 1.2em;
  }

  span {
    font-size: 0.8em;
    color: $grey-color;
  }
}

.post-content {
  border-bottom: 1px dashed $code-border-color;
  padding-bottom: 1em;
  margin-bottom: 1em;
  line-height: 1.7em;

  ol, ul {
    padding: 1em 1.3em;
  }

  .post-picture {
    padding: 0.8em 3%;
    width: 94%;
  }

  h2 {
    margin: 1em 0;
  }
}

.back-icon {
  padding-right: .3em;
}

:not(pre) > code {
  line-height: .9em;
  background-color: $code-background;
  border: 1px solid $code-border-color;
  padding: 0.3em;
  position: relative;
  bottom: 0.1em;
}

pre {
  display: block;
  overflow-x: auto;
}
