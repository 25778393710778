.main-info {
  margin-bottom: 2.5em;
}

.avatar {
  width: 110px;
  border-radius: 50%;

  @media (max-width: $screen-s) {
    display: none;
  }
}

.socials {
  float: right;
  position: relative;

  @media (max-width: $screen-s) {
    float: none;
    text-align: center;
  }
}

.chicken {
  position: absolute;
  top: -10px;
  right: 50px;
  width: 130px;

  @media (max-width: $screen-s) {
    top: -14px;
  }
}

.socials-links {
  text-align: center;

  a {
    padding: 0 .4em;
    border: none;
    font-size: 1.7em;
  }
}

.icon-github:hover, .icon-at:hover {
  color: #000;
}

.icon-linkedin:hover {
  color: #1B85BC;
}

.icon-vk:hover {
  color: #54769A;
}
