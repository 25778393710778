$font-family: Lato, PT Sans, Arial, sans-serif;

$text-color: rgba(0, 0, 0, .8);
$text-color-hover: rgba(0, 0, 0, .5);

$grey-color: rgba(136, 136, 136, .7);
$green-color: rgba(54, 136, 68, .5);

$code-background: #F8F8F8;
$code-border-color: #DDD;

$screen-s: 600px;
